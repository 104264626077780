$primary-color: #536b9a;
$secondary-corlor: #f5f5f5;

$button_main: #5b85e4;
$black: #1d152a;
$orange: #ebb271;
$purple: #7f70a0;
$green: #70a080;
$pink: #e29589;
// BUTTONS
$save: #70a080;
$add: #536b9a;
$delete: red;
