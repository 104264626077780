.dashboard_navigation {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  z-index: 9;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
  .sidebar_toggle {
    color: #7c9ed5;
    background: none;
    font-size: 35px;
    border: 0;
    cursor: pointer;
  }
  h2 {
    font-weight: bold;
  }
}
