.admin_dashboard {
  display: flex;
  overflow: hidden;
  height: 100vh;
  &_content {
    flex: 1;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    overflow: auto;
    background: #f5f5f5;
    height: 100vh;
    &_component {
      padding: 3%;
    }
  }
}

.admin_dashboard_content {
  &::-webkit-scrollbar {
    display: none;
  }
  &_wrapper {
    padding: 3%;
    @include for-tablet-portrait {
      padding: 10%;
    }
  }
}
