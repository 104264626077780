.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  font-family: "Poppins" !important;
  background-color: white;
  padding: 24px !important;
  width: 650px !important;
  min-height: 200px !important;
  border-radius: 16px !important;
  overflow-y: hidden !important;
  // overflow-y: scroll !important;
  @include for-phone-only {
    margin: 10px !important;
    padding: 5px !important;
  }
  .dialog_header {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
    h4 {
      margin-bottom: 0px !important;
    }
    .back_icon {
      font-size: 25px;
      cursor: pointer;
    }
    .close_icon {
      font-size: 25px;
      cursor: pointer;
    }
  }
  .upload_img {
    margin-bottom: 10px;
    width: 100%;
    @include for-phone-only {
      width: 100%;
    }
    p {
      font-weight: 600;
    }
    .upload_img_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .upload_img_input {
        padding: 10px;
        border: 1px dashed rgb(0, 128, 0);
        border-radius: 10px;
        align-items: center;
        cursor: pointer;
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: space-evenly;
        margin-bottom: 10px;
      }
    }
    .thumbnail {
      width: 100%;
      height: 250px;
      border-radius: 10px;
      border: 1px solid rgb(235, 178, 113);
      max-width: 100%;
      object-fit: cover;
      margin-top: 10px;
      @include for-phone-only {
        height: 150px;
      }
    }
  }

  .upload_multiple_img {
    // width: 50%;
    p {
      font-weight: 600;
    }
    &_images {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      margin-top: 10px;
      margin-bottom: 10px;
      img {
        height: 60px;
        border-radius: 10px;
        border: 1px solid rgb(235, 178, 113);
        max-width: 100%;
        width: 90px;
        object-fit: cover;
      }
      .delete_img {
        color: red;
        position: absolute;
        left: 80px;
        top: -12px;
        cursor: pointer;
        font-size: 20px;
      }
      .add_new_image {
        padding: 10px;
        border: 1px dashed rgb(0, 128, 0);
        border-radius: 10px;
        align-items: center;
        cursor: pointer;
        display: flex;
        width: 90px;
        height: 60px;
        justify-content: space-evenly;
      }
    }
  }
  select {
    background-color: #f5f5f5;
    border-radius: 10px;
    position: relative;
    border: 1px solid #e8e8e8;
    color: #000;
    width: 100%;
    font-size: 15px;
    padding: 13px 15px 13px 15px;
    font-family: "Poppins";
  }
  .save_product {
    border: 0;
    padding: 5px 15px;
    border-radius: 20px;
    background-color: #70a080;
    color: #fff;
  }
}

.css-uhb5lp {
  font-family: "Poppins" !important;
  background-color: white !important;
  padding: 24px !important;
  width: 650px !important;
  height: 560px !important;
  border-radius: 16px !important;
  overflow-y: hidden !important;
  // overflow-y: scroll !important;
  @include for-phone-only {
    margin: 10px !important;
    padding: 5px !important;
  }
  .dialog_header {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
    h4 {
      margin-bottom: 0px !important;
    }
    .back_icon {
      font-size: 25px;
      cursor: pointer;
    }
    .close_icon {
      font-size: 25px;
      cursor: pointer;
    }
  }
  .upload_img {
    margin-bottom: 10px;
    width: 50%;
    @include for-phone-only {
      width: 100%;
    }
    p {
      font-weight: 600;
    }
    .upload_img_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .upload_img_input {
        padding: 10px;
        border: 1px dashed rgb(0, 128, 0);
        border-radius: 10px;
        align-items: center;
        cursor: pointer;
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: space-evenly;
        margin-bottom: 10px;
      }
    }
    .thumbnail {
      width: 100%;
      height: 150px;
      border-radius: 10px;
      border: 1px solid rgb(235, 178, 113);
      max-width: 100%;
      object-fit: cover;
      margin-top: 10px;
    }
  }

  .upload_multiple_img {
    // width: 50%;
    p {
      font-weight: 600;
    }
    &_images {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      margin-top: 10px;
      margin-bottom: 10px;
      img {
        height: 60px;
        border-radius: 10px;
        border: 1px solid rgb(235, 178, 113);
        max-width: 100%;
        width: 90px;
        object-fit: cover;
      }
      .delete_img {
        color: red;
        position: absolute;
        left: 80px;
        top: -12px;
        cursor: pointer;
        font-size: 20px;
      }
      .add_new_image {
        padding: 10px;
        border: 1px dashed rgb(0, 128, 0);
        border-radius: 10px;
        align-items: center;
        cursor: pointer;
        display: flex;
        width: 90px;
        height: 60px;
        justify-content: space-evenly;
      }
    }
  }
  select {
    background-color: #f5f5f5;
    border-radius: 10px;
    position: relative;
    border: 1px solid #e8e8e8;
    color: #000;
    width: 100%;
    font-size: 15px;
    padding: 13px 15px 13px 15px;
    font-family: "Poppins";
  }
  .save_product {
    border: 0;
    padding: 5px 15px;
    border-radius: 20px;
    background-color: #70a080;
    color: #fff;
  }
}

.css-ypiqx9-MuiDialogContent-root {
  overflow: scroll !important;
  &::-webkit-scrollbar {
    width: 0.3em !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e8e8e8 !important;
    outline: 1px solid #e8e8e8 !important;
  }
}
.css-1ty026z {
  overflow: scroll !important;
  &::-webkit-scrollbar {
    width: 0.3em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e8e8e8 !important ;
    outline: 1px solid #e8e8e8 !important;
  }
}

.location_modal {
  width: 100%;
  .location_modal_cities {
    width: 100%;
    margin-bottom: 15px;
    margin-top: 15px;
    height: 330px;
    overflow-y: scroll;
    max-height: 400px;
    &::-webkit-scrollbar {
      width: 0.3em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e8e8e8;
      outline: 1px solid #e8e8e8;
    }

    .location_modal_city {
      cursor: pointer;
      border-bottom: 1px solid rgb(241, 238, 252);
      height: 40px;
      padding: 8px 8px;
      &:hover {
        background: #eee;
      }
      p {
        color: black;
      }
    }
  }
}
