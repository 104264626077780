.input_custom {
  display: flex;
  flex-direction: column;
  width: 100%;
  // @include for-tablet-portrait {
  //   width: 93%;
  // }
  input {
    background-color: transparent;
    border-radius: 5px;
    position: relative;
    border: 1px solid #e8e8e8;
    color: #000;
    width: 100%;
    font-size: 15px;
    padding: 13px 15px 13px 15px;
    font-family: "Poppins";
    &::placeholder {
      color: #777;
    }
  }
}

// input[type="file"]::file-selector-button {
//   border: 2px solid #6c5ce7;
//   padding: 0.2em 0.4em;
//   border-radius: 0.2em;
//   background-color: #a29bfe;
//   transition: 1s;
// }

// input[type="file"]::file-selector-button:hover {
//   background-color: #81ecec;
//   border: 2px solid #00cec9;
// }
