.main_button {
  width: 100%;
  padding: 5px 15px;
  border: 1px solid $button_main;
  border-radius: 50px;
  color: #fff;
  z-index: 1;
  background: $button_main;
  position: relative;
  transition: all 250ms;
  overflow: hidden;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 15px;
    background-color: #fff;
    z-index: -1;
    -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
    box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
    transition: all 250ms;
  }
  &:hover {
    color: $button_main;
  }
  &:hover::before {
    width: 100%;
  }
}

.save {
  border: 1px solid $save;
  background: $save;
  &:hover {
    color: $save;
  }
}

.add {
  border: 1px solid $add;
  background: $add;
  &:hover {
    color: $add;
  }
}

.delete {
  border: 1px solid $delete;
  background: $delete;
  &:hover {
    color: $delete;
  }
}

.other {
  border: 1px solid $black;
  background: $black;
  &:hover {
    color: $black;
  }
}

.pink {
  border: 1px solid $pink;
  background: $pink;
  &:hover {
    color: $pink;
  }
}

.purple {
  border: 1px solid $purple;
  background: $purple;
  &:hover {
    color: $purple;
  }
}

.white {
  border: 1px solid #fff;
  background: #fff;
  color: #000;
  &:hover {
    color: #000;
  }
}
