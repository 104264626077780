.admin_sidebar {
  position: sticky;
  top: 0;
  left: 0;
  width: 300px;
  z-index: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;

  padding: 25px;
  border-right: 1px solid #eee;
  min-height: 100vh;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
  //   rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  @include bezier-transition(all, 0.25s, ease-in-out);
  @include for-tablet-portrait {
    width: 100px;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eee;
    border-radius: 10px;
  }

  .logo-img {
    margin: 15px 5px;

    @include for-tablet-portrait {
      margin: 0 auto;
      width: 70px;
    }
  }

  .admin_sidebar_content {
    margin: 20px 0;
    // padding: 0 0 0 40px;
    @include for-tablet-portrait {
      // padding: 0 0 0 25px;
    }
    .menu_title {
      text-transform: uppercase;
      font-size: 16px;
      color: rgb(187, 187, 187);
      padding: 0 0 0 10px;
      @include for-tablet-portrait {
        padding: 0;
        font-size: 13px;
      }
    }
    .admin_sidebar_menu {
      margin: 15px 10px;
      @include for-tablet-portrait {
        margin: 15px -3px;
      }
      .sidebar_link {
        text-decoration: none;
        padding: 15px;
        display: flex;
        align-items: center;
        color: #777;
        justify-content: space-between;
        position: relative;
        cursor: pointer;
        svg {
          font-size: 30px;
          color: #7c9ed5;
        }
        span {
          font-size: 17px;
          font-weight: bold;
          @include for-tablet-portrait {
            display: none;
          }
        }
        &:hover {
          background-color: #eff3fe;
          border-radius: 10px;
          svg {
            color: #7c9ed5;
          }
        }

        .flexed_div {
          display: flex;
          align-items: center;
          gap: 15px;
        }
      }
      .adminActiveLink {
        background-color: #eff3fe;
        border-radius: 10px;
        color: #7c9ed5;
        svg {
          color: #7c9ed5;
        }
      }
    }
  }
}

.admin_sidebar_none {
  width: 0;
}
