@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import "../utils/mixins";
@import "../utils/animation";
@import "../utils/colors";

html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.body-style {
  overflow-y: hidden;
}

body {
  font-family: "Poppins", sans-serif !important;
  background: #f5f5f5 !important;
  overflow-x: hidden !important;
  iframe {
    z-index: -2 !important;
  }
  &::-webkit-scrollbar {
    width: 0.3em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e8e8e8;
    outline: 1px solid #e8e8e8;
  }

  ::-webkit-selection {
    color: #fff;
    background: #7c9ed5;
  }

  ::-moz-selection {
    color: #fff;
    background: #7c9ed5;
  }

  ::selection {
    color: #fff;
    background: #7c9ed5;
  }
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

p {
  margin-bottom: 0 !important;
}

*:focus {
  outline: none;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: #9b9b9b;
  border-radius: 10px;
}

.css-1pxa9xg-MuiAlert-message {
  font-family: "Poppins" !important;
}

.css-13cymwt-control {
  background-color: #f5f5f5 !important;
  border-radius: 50px !important;
  position: relative !important;
  border: 1px solid #e8e8e8 !important;
  width: 100% !important;
  font-size: 15px !important;
  padding: 8px 10px !important;
  box-shadow: none !important;
  font-family: "Poppins" !important;
  .css-1jqq78o-placeholder {
    color: #000 !important;
  }
}

.css-t3ipsp-control {
  background-color: #f5f5f5 !important;
  border-radius: 50px !important;
  position: relative !important;
  border: 1px solid #e8e8e8 !important;
  width: 100% !important;
  font-size: 15px !important;
  padding: 8px 10px !important;
  font-family: "Poppins" !important;
  box-shadow: none !important;
  .css-1jqq78o-placeholder {
    color: #000 !important;
  }
}

.dublo {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
  @include for-phone-only {
    flex-direction: column;
  }
}

.css-13cymwt-control {
  padding: 0px !important;
}
