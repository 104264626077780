.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #eef1fa;
  .login_content {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    font-family: "Poppins";
    // background-image: url(../assets/images/bg3.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    padding: 3%;
    transition: all 0.5s ease 0s;
    width: 550px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: rgba(255, 255, 255, 0.8);
    @include for-tablet-portrait {
      width: 90%;
    }
    .logo {
      width: 200px;
      align-items: center;
      justify-content: center;
      display: flex;
      margin: 0 auto;
      margin-bottom: 20px;
    }
    h3 {
      text-align: center;
      font-size: 18px;
      color: #777;
      padding-bottom: 20px;
    }
    .login_content_actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 30px;
      // margin-bottom: 20px;
      align-items: center;
      p {
        a {
          color: #ebb271;
          text-decoration: none;
        }
      }
      .sign_in {
        border: 0;
        padding: 7px 10px;
        border-radius: 50px;
        background-color: #ebb271;
        color: #fff;
      }
    }
    .social_content {
      display: flex;
      align-items: center;
      justify-content: center;
      .socials {
        border: 0;
        padding: 20px;
        background: none;
        width: 70px;
        align-items: center;
        justify-content: center;
        display: flex;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
}
